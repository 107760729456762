.unbxd-as-wrapper {
  background-color: #fff;
  z-index: 1000000;
  min-height: 200px !important;
  overflow: hidden;
  border-radius: 0;
  padding: 16px;
  padding-top: 19px;
  box-shadow: 0 8px 16px 0 #33333329;
  font-size: 1.4rem;
  font-weight: 500;
  color: black;
  display: flex;
  flex-direction: row-reverse;
}
.unbxd-as-mobile.unbxd-as-wrapper {
  width: 100% !important;
  left: 0 !important;
  overflow-y: scroll;
  height: 80%;
}
.unbxd-autosuggest-desktop .unbxd-as-wrapper {
  max-width: 700px !important;
}
.unbxd-as-wrapper li .unbxd-as-popular-product-info {
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  color: #000;
  min-width: 135px;
}
.unbxd-as-wrapper li .unbxd-oss-name {
  font-weight: 400;
  min-height: 16px;
  max-height: 32px;
  overflow: hidden;
  color: #000;
  margin-bottom: 4px;
  margin-top: 10px;
}
.unbxd-as-wrapper li .unbxd-oss-supporttext {
  font-weight: 450;
  font-size: 10px;
  line-height: 12px;
  min-height: 12px;
  max-height: 24px;
  overflow: hidden;
  color: #919191;
  margin-bottom: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
}
.unbxd-as-wrapper li .unbxd-oss-image {
  height: auto;
  width: 100%;
  object-fit: contain;
  border-radius: 0;
  max-width: 100%;
  display: inline-block;
  opacity: 1;
  min-height: 144px;
}
.unbxd-as-wrapper li .unbxd-was-price {
  margin-top: 2px;
  font-weight: 450;
  font-size: 10px;
  line-height: 10px;
  color: #919191;
}

@media (min-width: 1200px) {
  .unbxd-as-wrapper {
    width: 700px !important;
  }
}

.unbxd-as-wrapper ul {
  list-style-position: outside;
  list-style: none;
  padding: 0;
  margin: 0;
}

.unbxd-as-wrapper ul li {
  white-space: normal;
  padding: 4px 9px;
  cursor: pointer;
}

.unbxd-as-extra-right:after {
  clear: both;
  content: '.';
  display: block;
  font-size: 0;
  height: 0;
  visibility: hidden;
}

.unbxd-as-extra-right .unbxd-as-maincontent {
  float: left;
  padding-bottom: 10px;
}

.unbxd-as-extra-right .unbxd-as-sidecontent {
  float: right;
  background-color: #fff;
  padding-top: 0 !important;
}

.unbxd-as-wrapper ul li.unbxd-as-header,
.unbxd-as-wrapper ul li.unbxd-as-header:hover {
  line-height: 1;
  text-transform: uppercase;
  font-weight: 500;
  color: #000 !important;
  font-size: 14px;
  letter-spacing: 0;
  margin: 0 8px 0;
}
.unbxd-as-wrapper ul li.unbxd-as-popular-product:after,
.unbxd-as-wrapper ul li.unbxd-as-popular-product .unbxd-as-popular-product-info:after {
  clear: both;
  content: '.';
  display: block;
  font-size: 0;
  height: 0;
  visibility: hidden;
}
.unbxd-as-wrapper ul li.unbxd-as-header {
  padding: 0;
  padding-bottom: 10px;
}
.unbxd-as-wrapper ul li.unbxd-as-popular-product-grid:hover {
  background-color: #f6f6f6 !important;
  box-shadow: none !important;
}
.unbxd-as-wrapper ul li.unbxd-as-popular-product {
  padding-left: 4px;
  padding-right: 4px;
}
.unbxd-as-wrapper ul li .unbxd-as-popular-product-price {
  float: right;
}
.unbxd-sale-price {
  font-weight: 500;
}
.unbxd-discount {
  font-weight: normal;
  color: #07b323;
  text-decoration: none;
  font-size: 10px;
}
.unbxd-was-price {
  text-decoration: line-through;
  font-weight: normal !important;
}
.unbxd-as-wrapper ul li:hover {
  cursor: pointer;
}
.unbxd-as-wrapper ul li.unbxd-as-popular-product-grid:hover {
  background-color: white;
  box-shadow: 0 0 20px whitesmoke;
}
.unbxd-as-wrapper ul li.unbxd-as-popular-product-grid {
  float: left;
  box-sizing: border-box;
  padding: 1%;
  border: 1px solid white;
}

@supports (-ms-ime-align: auto) {
  .unbxd-as-wrapper ul li.unbxd-as-popular-product-grid {
    box-sizing: border-box;
  }
}

.unbxd-as-wrapper ul li.unbxd-as-popular-product-grid:hover .unbxd-as-popular-product-image-container {
  border: 0;
}
.unbxd-as-wrapper ul li.unbxd-as-popular-product-grid .unbxd-as-popular-product-name {
  padding-top: 5px;
  display: inline-block;
  cursor: pointer;
  overflow: hidden;
  width: 100%;
  white-space: normal;
  text-decoration: none;
  color: #2d3746;
}
.unbxd-as-wrapper ul li.unbxd-as-popular-product-grid .unbxd-as-popular-product-price {
  float: none;
}
.unbxd-as-sidecontent + .unbxd-as-maincontent {
  padding-top: 10px 0;
  min-width: 200px;
}
.unbxd-as-extra-right .unbxd-as-maincontent {
  float: left;
}
.unbxd-as-wrapper ul {
  list-style-position: outside;
  list-style: none;
  padding: 0;
  margin: 0;
}
.unbxd-as-wrapper ul li {
  cursor: pointer;
  padding: 5px 5px 5px 10px;
  margin: 5px 0;
  line-height: 18px;
  font-weight: 500;
  font-size: 16px;
  text-align: left;
  color: inherit;
  box-sizing: content-box;
}
.unbxd-as-wrapper ul {
  list-style-position: outside;
  list-style: none;
  padding: 0;
  margin: 0;
}
.unbxd-as-wrapper ul li.unbxd-as-header,
.unbxd-as-wrapper ul li.unbxd-as-header:hover {
  cursor: default;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 300;
  box-sizing: content-box;
}
ul li.unbxd-as-popular-product-grid {
  width: 33%;
  margin: auto;
  position: relative;
  vertical-align: top;
  text-align: center;
  display: inline-block;
  padding: 0;
}
.unbxd-as-wrapper ul li.unbxd-as-popular-product-grid .unbxd-as-popular-product-price {
  float: none;
}
.unbxd-as-popular-product.unbxd-as-popular-product-grid:hover .unbxd-as-popular-product-name {
  text-decoration: underline !important;
}
.unbxd-as-maincontent,
.unbxd-as-sidecontent {
  height: 100%;
}
.unbxd-as-sidecontent {
  width: 75%;
  height: inherit;
}

body[data-domain='nnormal'] .unbxd-autosuggest-desktop .unbxd-as-wrapper,
body[data-domain='nnormal'] .unbxd-autosuggest-tablet .unbxd-as-wrapper {
  max-width: 550px !important;
}

body[data-domain='nnormal'] .unbxd-as-sidecontent {
  width: 100%;
}

.unbxd-as-maincontent {
  width: 25%;
  height: inherit;
  padding-right: 10px;
}

.unbxd-as-topqueries {
  margin: 0 !important;
  box-sizing: border-box;
  cursor: pointer;
  padding: 4px 8px !important;
  display: flex;
  align-items: center;
  font-size: 12px !important;
  line-height: 16px;
  height: auto !important;
  white-space: normal;
  box-sizing: border-box !important;
  overflow: hidden;
}
.unbxd-autosuggest-mobile {
  display: none;
}

@media screen and (max-height: 768px) {
  .unbxd-as-sidecontent {
    max-height: 82%;
  }
}

@media only screen and (max-width: 768px) {
  .unbxd-autosuggest-desktop {
    display: none;
  }
  .unbxd-as-wrapper {
    padding: 10px 16px 25px 16px;
    margin: 0;
  }
  .unbxd-autosuggest-mobile .unbxd-as-maincontent {
    width: 100% !important;
  }
  .unbxd-autosuggest-mobile {
    justify-content: center;
  }
  .unbxd-autosuggest-mobile {
    display: block;
  }
  .unbxd-as-wrapper ul li.unbxd-as-popular-product-grid {
    width: 50%;
  }
  .unbxd-as-wrapper ul li.unbxd-as-popular-product-grid .unbxd-as-popular-product-image-container {
    min-height: 90px;
  }
  .unbxd-as-header {
    padding-top: 15px;
  }
  .unbxd-as-wrapper ul li.unbxd-as-header:not(:first-child) {
    margin-top: 20px;
  }
  .unbxd-as-popular-product-price {
    min-height: 36px;
  }
  .unbxd-as-wrapper ul li.unbxd-as-popular-product-grid:hover {
    background-color: #fff !important;
    box-shadow: none !important;
  }
}
