
.phoneWrapper {
  display: flex;
}

.phoneWrapper :global .ant-form-item {
  width: 50%;
}

.phoneWrapper :global .ant-form-item-control,
.phoneWrapper :global .ant-form-item-control-input,
.phoneWrapper :global .ant-form-item-control-input-content, 
.phoneWrapper :global .ant-select,
.phoneWrapper :global .ant-select-selector {
  height: 100% !important;
}

.phoneWrapper > div:first-child :global .ant-form-item-children-icon {
  display: none;
}

.phoneWrapper :global .ant-select-selector {
  align-items: center;
}

.phoneWrapper :global .ant-form-item-explain {
  display: none;
}

.phoneError {
  margin-bottom: 16px;
}