.customSelect :global .ant-select {
  width: 100%;
}
.customSelect :global .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: auto;
}
.customSelect :global .ant-select-selection-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.customSelect :global .ant-select-selection-item::after {
  display: none !important;
}
.customSelect :global .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0px;
}
.selectLabelP {
  margin-bottom: 0px;
  text-align: start;
  margin-right: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2;
  font-size: 14px;
}
.shipmentWrapper {
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 9px 15px;
}
.shipmentPrice {
  align-self: center;
}
.shipmentPrice > svg {
  margin-left: 10px;
}
.customSelect :global .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  /* border-bottom: 1px solid #d9d9d9; */
}
.customSelectDropdown :global .ant-select-item {
  border-top: 1px solid #d9d9d9;
  border-left: 1px solid var(--color-black);
  border-right: 1px solid var(--color-black);
  padding: 9px 15px;
}
.customSelectDropdown :global .ant-select-item-option-selected {
  background-color: var(--color-grey-light-two);
  font-weight: 500;
}
.customSelectDropdown :global .ant-select-item:last-child {
  border-bottom: 1px solid var(--color-black);
}
.selectShipment {
  padding: 13px 15px;
  min-width: 100%;
}
.selectShipment > p {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
  color: var(--color-black);
  font-size: var(--description-size);
}
.selectShipment > .chevronActive {
  align-self: center;
}
.shipmentBtnDescription {
  color: var(--color-disabled-primary);
  text-transform: uppercase;
  font-size: 11px;
}
.chevronActive > svg {
  transform: rotate(180deg);
}
.shipmentItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}
.shipmentItem > p {
  margin-bottom: 0px;
  text-align: start;
  margin-right: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2;
}
.uniqueShipment > p {
  white-space: normal;
}