.floatLabel {
  position: relative;
}
.label {
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 12px;
  top: 17px;
  transition: 0.2s ease all;
  color: var(--color-text-secondary);
}

input:not(:placeholder-shown) + .label {
  top: 6px;
  font-size: 0.625em;
}
.labelFloat {
  top: 6px;
}
.floatLabel > .label {
  font-size: var(--title-size);
}
.floatLabel > .labelFloat {
  font-size: 0.625em;
}
.floatLabel :global .ant-input {
  padding: 20px 12px 12px 11px;
  font-size: var(--title-size);
}

.floatLabel :global .ant-select .ant-select-selector {
  padding: 20px 12px 12px 11px;
}

.floatLabel :global .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 16px 12px 12px 11px;
  height: 56px;
}

.floatLabel :global .ant-select-single .ant-select-selector .ant-select-selection-search {
  top: 16px;
}

.floatLabel :global .ant-select-selection-search {
  top: 15px !important;
  font-size: var(--title-size);
}

.floatLabel :global .ant-select-selection-item {
  font-size: var(--title-size);
}
