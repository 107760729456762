@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --tw-color-primary: 237 26 44;
    --tw-color-disabled: 145 145 145;
    --tw-color-divider: 217 217 217;
    --tw-color-error: 218 41 28;
    --tw-color-success: 7 179 35;
    --tw-color-warning: 252 129 6;
    --tw-color-camperlab: 241 239 229;
    --tw-color-recycled: 237 241 198;
  }
  svg {
    display: inline;
    vertical-align: baseline;
  }
  img {
    display: inline;
    max-width: none;
    height: revert-layer;
  }
  .prose-counter {
    ol {
      counter-reset: item;
    }

    .prose-counter ol > li:before {
      content: counters(item, '.') ' ';
      counter-increment: item;
    }

    /* .prose-counter ol ol > li {
      display: block;
    } */

    /* .prose-counter ol ol > li:before {
      content: counters(item, '.') '. ';
    } */
  }
}

@layer utilities {
  .animated-underline {
    @apply relative transition before:absolute before:bottom-0 before:left-0 before:h-0 before:w-0 before:border-b before:duration-150 before:ease-in group-hover:before:w-full;
  }
}
